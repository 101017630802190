import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";

import Layout from "../components/layout";
import Seo from "../components/seo";

const landing = () => {
  return (
    <Layout pageInfo={{ pageName: "Terms" }}>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="DeFi Powered Rewards Generator" />
      <div className="py-140 mb-4  text-justified ">
        <Container>
          <Row className="mb-2 ">
            <Col md={12} className="mb-md-5 mb-4">
              <h1 align="center">Terms of Service</h1>
              <p>Welcome to Hedgepay. We are glad you are here, and we hope you enjoy everything we have to offer.</p>
              <p>
                These Terms govern your use of the products, services, and websites offered by HedgePay Sdn Bhd
                &#40;“HedgePay,” “Company,” “Website,” “We, or, “Us”&#41;, a company with limited liability incorporated and
                registered under the laws of Malaysia defined below. We refer to those products, services, and websites
                collectively as the “Services” in these Terms.
              </p>
              <p>
                These Terms of Service &#40;“Terms”&#41; constitute a legally binding agreement between you and HedgePay. By
                accessing the platform, connecting your wallet and/or purchasing our services, you agree to be bound by
                the Terms in force at all times of HedgePay.
              </p>
              <h2>Other relevant documents</h2>
              <p>
                These terms refer to other documents which apply to your use of services offered by Hedgepay. They are:
              </p>
              <p>Privacy Policy - Our policy on the protection of your Personal Data can be accessed here.</p>
              <p>
                Services List - List of the services and their respective prices are provided here. Additional Terms -
                Service-Specified Terms that will apply to your use of our services are available here.
              </p>
              <h2>Connecting your Wallet</h2>
              <p>
                You may connect your wallet by using any of the means provided for on the website. By connecting your
                wallet, you allow the Website to identify and/or recognize you as using the wallet address and the other
                specific technical information as required for such recognition.
              </p>
              <h2>Right to use the Website</h2>
              <p>
                You hereby agree to the following representations and warranties by accessing the Website and/or using
                the Services:
              </p>
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>You have the capacity under all applicable laws to agree and bind yourself to these Terms.</li>
                <li>You are sixteen years of age or older.</li>
                <li>You are not a citizen of a prohibited jurisdiction.</li>
                <li>
                  You are aware of and agree to comply with all applicable domestic and international legislation
                  applicable to the use of the Website and Services.
                </li>
                <li>
                  You do not hold tokens in your wallet that have been derived from money laundering, terrorist
                  financing, fraud, or any other illegal activities under any applicable laws.
                </li>
                <li>
                  You understand the risks associated with accessing or using the Services, and you will be fully liable
                  at your own risk.
                </li>
              </ol>
              <h2>Services</h2>
              <p>HPAD Launchpad</p>
              <p>HedgeFi Rewards</p>
              <p>HedgePay Staking</p>
              <p>HedgePay Audit & KYC</p>
              <p>HedgePay Aggregator DEX</p>
              <p>Hedge Talk AMA</p>
              <p>HedgePay E-commerce</p>
              <p>HedgePay Wallet</p>
              <p>HedgePay Freelancing</p>
              <p>Collateral & Lending</p>
              <p>HedgePay CRM</p>
              <h2>Third Party Services</h2>
              <p>
                The website may include links to other websites, applications, and platforms &#40;"Third-Party Websites"&#41; of
                our service provides and/or partners. We make no representations about those Third-Party Websites, and
                shall not be responsible for the content and other materials of those Third-Party Websites.
              </p>
              <h2>Copyrighted Work/Intellectual Property</h2>
              <p>
                The Website and all the Services, including their registered and unregistered trademarks, registered and
                unregistered designs, elements or concepts and any underlying intellectual property, including, but not
                limited to, all trademarks, all present and future intellectual property rights are the property of
                HedgePay and its Users where applicable, and are protected by copyright, patent, trade secret, and other
                intellectual property laws. Subject to your compliance with these Terms, the Company grants you a
                non-exclusive, non-transferable, revocable license, and any limited license to merely use or access the
                Website and the Services in the permitted hereunder.
              </p>
              <p>
                Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any
                right in or license to HedgePay or any other third party's intellectual rights.
              </p>
              <p>
                You may permit the Company to utilize trademarks, copyrighted material, and other Intellectual Property
                associated with their businesses. The Company will not warrant or represent that the content of the
                Website does not infringe the rights of any third party. You may not: Copy, create derivative works,
                distribute, publish, reverse engineer, decompile, disassemble, modify, or translate the HedgePay website
                or the Services; or Use the Services that in any way is prohibited by applicable laws or regulations
                &#40;each of the above herein defined as an "Unauthorized Use"&#41;. You agree that you will be solely
                responsible for any damage, costs, or expenses arising out of or in connection with any unauthorized use
                by you.
              </p>
              <h2>Warranty Disclaimer</h2>
              <p>
                Except as expressly provided in these SSTS and to the extent permitted by applicable law, HPAD, its
                content, and any services found or accessed through this website are provided on an “as is” or “as
                available” basis, and HedgePay does not make warranties of any kind, express, implied, or statutory,
                including, but not limited to, those of merchantability, fitness for a particular purpose, and
                non-infringement. Neither the company nor its parent, subsidiaries, affiliates, or their respective
                directors, officers, employees, agents, service providers, contractors, licensors, licensees, suppliers,
                or successors make any warranty, representation, or endorsement with respect to the completeness,
                security, reliability, suitability, accuracy, currency, or availability of the website or its contents.
                Without limiting the foregoing, neither the company nor its parent, subsidiaries, affiliates or their
                respective directors, officers, employees, agents, service providers, contractors, licensors, licensees,
                suppliers, or successors represent or warrant that the website, its content, or any services or items
                found or attained through the website will be accurate, reliable, error-free, or uninterrupted, that
                defects will be corrected. We cannot and do not guarantee or warrant that files or data available for
                downloading from the internet or the website will be free of viruses or other destructive code. You are
                solely and entirely responsible for your use of the website and your computer, internet, and data
                security. To the fullest extent provided by law, we will not be liable for any loss or damage caused by
                denial-of-service attack, distributed denial-of-service attack, overloading, flooding, mailbombing, or
                crashing, viruses, trojan horses, worms, logic bombs, or other technologically harmful material that may
                infect your computer equipment, computer programs, data, or other proprietary material due to your use
                of the website or any services or items found or attained through the website or to your downloading of
                any material posted on it, or on any website linked to it.
              </p>
              <h2>Limitation on Liability</h2>
              <p>
                Except where such exclusions are prohibited by law, under no circumstance will the company nor its
                parent, subsidiaries, affiliates or their respective directors, officers, employees, agents, service
                providers, contractors, licensors, licensees, suppliers, or successors be liable for negligence, gross
                negligence, negligent misrepresentation, fundamental breach, damages of any kind, under any legal
                theory, including any direct, indirect, special, incidental, consequential, or punitive damages,
                including, but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue,
                loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data,
                and whether caused by tort &#40;including negligence&#41;, breach of contract, breach of privacy, or otherwise,
                even if the party was allegedly advised or had reason to know, arising out of or in connection with your
                use &#40;or misuse&#41;, or inability to use, or reliance on, the website, any linked websites or such other
                third-party websites, nor any website content, materials, posting, or information thereon even if the
                party was allegedly advised or had reason to know. Nothing in these terms and conditions shall exclude
                or limit the company's liability for death or personal injury resulting from its negligence.
              </p>
              <h2>Indemnification</h2>
              <p>
                To the maximum extent applicable by law, you will indemnify and hold harmless HedgePay &#40;including its
                affiliates and its and their officers, agents, and employees&#41; from all liabilities, damages, and costs
                &#40;including settlement costs and reasonable attorneys' fees&#41; arising out of a third-party claim regarding
                or in connection with your or your end users' use of the Services or breach of these Terms, to the
                extent that such liabilities, damages, and costs were caused by you or your end users.
              </p>
              <h2>Termination</h2>
              <p>i&#41; Termination by you</p>
              <p>
                You may terminate the operation of these terms and the Contract between you HedgePay therein by
                discontinuing your use of or participation in the Services and by such immediate termination you agree
                to terminate the accessibility on the Website.
              </p>
              <p>ii&#41; Termination by HedgePay</p>
              <h2>For Convenience</h2>
              <p>
                We reserve the right to modify, suspend or discontinue all or any part of the Services at any time
                without prior notice or liability.
              </p>
              <h2>For Cause</h2>
              <p>
                We reserve the right to modify, suspend or terminate the operation of these terms and your access to the
                Services without notice from us, if there is reasonable ground for us to believe that you have breached
                any of the provisions stipulated in these Terms &#40;“Breach of Contract”&#41;.
              </p>
              <p>
                The termination of these Terms will not prevent us from seeking remedies from you for Breach of Contract
                leading to such termination. We will not be liable to you or to any third party for any termination,
                suspension, or modification of your access to the Services.
              </p>
              <p>
                Not in contrary to the above, any ongoing obligation between the parties as well as the provisions
                regarding &#40;i&#41; Intellectual Property, &#40;ii&#41; Indemnification, &#40;iii&#41; Limitation of liability, and &#40;iv&#41; any
                other provisions designed to prevail, will prevail any modification, suspension, or termination of the
                operation of these Terms.
              </p>
              <h2>Governing Law and Dispute Resolution</h2>
              <p>
                The use of this Website, these Terms of Service, any service-specific agreement whereby we provide you
                with any Services and any dispute arising out or in relation the Agreement, any Contract for Service or
                any other such agreement &#40;“Dispute”&#41; between you and HedgePay &#40;“The Parties”&#41; shall be governed by and
                construed in accordance with the laws of Malaysia applicable therein, without giving effect to any
                choice or conflict of law provision, principle, or rule &#40;whether of the laws of the Malaysia or any
                other jurisdiction&#41; and notwithstanding your domicile, residence, or physical location.
              </p>
              <p>
                Any dispute shall be referred to and finally resolved by arbitration under the DIFC-LCIA Rules
                &#40;hereinafter referred to as the “Rules”&#41;, which Rules are deemed to be incorporated by reference into
                this clause. The number of arbitrators shall be one &#40;1&#41;. The language to be used in the arbitral
                proceedings shall be English. The seat, or legal place, of arbitration shall be Dubai, United Arab
                Emirates. The arbitration procedure may be conducted partially or entirely online.
              </p>
              <p>
                The Parties agree that any dispute is personal to you and the Company and that any dispute shall only be
                resolved by individual litigation and shall not be brought as a class action or any other representative
                proceeding. The Parties agree that a dispute cannot be brought as a class or representative action or on
                behalf of any other person or persons.
              </p>
              <p>
                In case of dispute, the Parties shall maintain the confidentiality of any proceedings, including but not
                limited to any and all information gathered, prepared, and presented for purposes of the litigation or
                related to the dispute&#40;s&#41; therein.
              </p>
              <p>
                Notwithstanding the above, nothing in this clause shall deem to waive, preclude or otherwise limit the
                right of either party to: &#40;i&#41; bring an individual action in small claims court; &#40;ii&#41; pursue an
                enforcement action through the applicable federal, state, or local agency if that action is available;
                &#40;iii&#41; seek injunctive relief in aid of arbitration from a court of competent jurisdiction; or &#40;iv&#41; to
                file suit in a court of law to address an intellectual property infringement claim.
              </p>
              <p>
                Nothing in this clause shall limit the right of the Company to take proceedings against you in any other
                court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions
                preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the
                extent permitted by the law of such other jurisdiction, where criminal liability can be imposed on you
                under and in terms of the Law of Malaysia.
              </p>
              <h2>Entire Agreement</h2>
              <p>
                Our Privacy Policy, these Terms of Service, and Service-Specific Terms, if any, with reference to the
                service&#40;s&#41; you are obtaining shall constitute the Entire Agreement between you and HedgePay with respect
                to your use of this website &#40;sometimes referred to as the “Agreement”&#41;
              </p>
              <h2>Severability</h2>
              <p>
                If any provision of these Terms of Service or part thereof, as amended from time to time, is determined
                to be invalid, void, or unenforceable, in whole or in part, by any court of competent jurisdiction, such
                invalidity, voidness, or unenforceability attaches only to such provision to the minimum extent
                necessary, and the remaining provisions herein shall be in force and effect fully.
              </p>
              <h2>Reporting and Contact</h2>
              <p>
                All the communications and other requests relating to the use of the Website shall be directed to our
                request form here.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default landing;
